import React from "react"
import { graphql } from "gatsby"
import PageLayout from "./page-layout"
import BlogCard from "../components/blog-card"
import Wrapper from "../components/wrapper"

export default function CategoryPage({
  data: {
    allMarkdownRemark,
    markdownRemark: {
      frontmatter: { title, img, name },
      fields,
    },
  },
  pageContext: { lang },
}) {
  const isAR = lang === "ar"
  return (
    <PageLayout
      breadcrumbsPaths={[
        { title: isAR ? "المدونة" : "Blog", path: `${isAR ? "" : "/en"}/blog` },
        { title: title, path: "" },
      ]}
      mainImage={img?.childImageSharp?.fluid || {}}
      title={"قسم: " + title}
    >
      <Wrapper>
        {allMarkdownRemark.edges?.map(({ node }) => (
          <BlogCard
            to={node.fields.slug}
            {...node.frontmatter}
            sx={{ mb: 4 }}
            key={node.id}
          />
        ))}
      </Wrapper>
    </PageLayout>
  )
}

export const categoryQuery = graphql`
  query($name: String!) {
    markdownRemark(frontmatter: { name: { eq: $name } }) {
      frontmatter {
        name
        title
        img {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/blog/" }
        frontmatter: { category: { eq: $name } }
      }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            description
            date(formatString: "YYYY. MM. DD")
            author
            category
            img {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
